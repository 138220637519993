import * as Types from '../../../types/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FunctionalityQueryVariables = Types.Exact<{
  companyId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  customerId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type FunctionalityQuery = (
  { __typename?: 'Query' }
  & { functionality: (
    { __typename?: 'Functionality' }
    & Pick<Types.Functionality, 'isHealthcareWorker' | 'isSupplier' | 'perms' | 'showCorporateAdminViews' | 'showRegionalAdminViews' | 'showCustomerAdminViews' | 'showHealthcareWorkerViews' | 'showSupplierViews' | 'primaryUi' | 'language'>
    & { companies: Array<(
      { __typename?: 'UserRoleCompanyBaseInfo' }
      & Pick<Types.UserRoleCompanyBaseInfo, 'id' | 'name'>
    )>, customers: Array<(
      { __typename?: 'UserRoleCustomerBaseInfo' }
      & Pick<Types.UserRoleCustomerBaseInfo, 'id' | 'name'>
    )> }
  ), medicalPermissionsForUser: Array<(
    { __typename?: 'MedicalPermissionType' }
    & Pick<Types.MedicalPermissionType, 'id' | 'codename' | 'name'>
  )> }
);


export const FunctionalityDocument = gql`
    query functionality($companyId: ID, $customerId: ID) {
  functionality(companyId: $companyId, customerId: $customerId) {
    isHealthcareWorker
    isSupplier
    perms
    showCorporateAdminViews
    showRegionalAdminViews
    showCustomerAdminViews
    showHealthcareWorkerViews
    showSupplierViews
    primaryUi
    language
    companies {
      id
      name
    }
    customers {
      id
      name
    }
  }
  medicalPermissionsForUser {
    id
    codename
    name
  }
}
    `;

/**
 * __useFunctionalityQuery__
 *
 * To run a query within a React component, call `useFunctionalityQuery` and pass it any options that fit your needs.
 * When your component renders, `useFunctionalityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFunctionalityQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useFunctionalityQuery(baseOptions?: Apollo.QueryHookOptions<FunctionalityQuery, FunctionalityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FunctionalityQuery, FunctionalityQueryVariables>(FunctionalityDocument, options);
      }
export function useFunctionalityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FunctionalityQuery, FunctionalityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FunctionalityQuery, FunctionalityQueryVariables>(FunctionalityDocument, options);
        }
export function useFunctionalitySuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FunctionalityQuery, FunctionalityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FunctionalityQuery, FunctionalityQueryVariables>(FunctionalityDocument, options);
        }
export type FunctionalityQueryHookResult = ReturnType<typeof useFunctionalityQuery>;
export type FunctionalityLazyQueryHookResult = ReturnType<typeof useFunctionalityLazyQuery>;
export type FunctionalitySuspenseQueryHookResult = ReturnType<typeof useFunctionalitySuspenseQuery>;
export type FunctionalityQueryResult = Apollo.QueryResult<FunctionalityQuery, FunctionalityQueryVariables>;
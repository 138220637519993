export const join_paths = (
    urls: string[],
    absolute: boolean = true,
): string => {
    const initial = absolute ? "/" : "";
    if (urls.length === 0) {
        return initial;
    } else {
        return urls.reduce((prev, currentValue) => {
            if (prev === "") {
                return currentValue;
            } else if (currentValue === "") {
                return prev;
            } else if (prev.endsWith("/") || currentValue.startsWith("/")) {
                return prev + currentValue;
            } else {
                return prev + "/" + currentValue;
            }
        }, initial as string);
    }
};

export const urlIsCorporateAdmin = (url: string): boolean => {
    return url.includes("/" + CORPORATE_ADMIN_PREFIX);
};
export const urlIsRegionalAdmin = (url: string): boolean => {
    return url.includes("/" + REGIONAL_ADMIN_PREFIX);
};
export const urlIsDoctor = (url: string): boolean => {
    return url.includes("/" + DOCTOR_PREFIX);
};
export const urlIsSupplier = (url: string): boolean => {
    return url.includes("/" + SUPPLIER_PREFIX);
};
export const urlIsCustomerAdmin = (url: string): boolean => {
    return (
        !urlIsCorporateAdmin(url) &&
        !urlIsRegionalAdmin(url) &&
        !urlIsDoctor(url) &&
        !urlIsSupplier(url)
    );
};

export const makeAbsolute = (url: string): string => {
    return url.startsWith("/") ? url : "/" + url;
};

export const CORPORATE_ADMIN_PREFIX = "corporateadmin";
export const REGIONAL_ADMIN_PREFIX = "regionaladmin";
export const CUSTOMER_ADMIN_PREFIX = "";
export const DOCTOR_PREFIX = "doctor";
export const SUPPLIER_PREFIX = "supplier";
// Common
export const HOME = "main";
export const CREATE = "create";
export const SELECT = "select";
export const EDIT = "edit";

// Auth
export const TOKEN = ":token";
export const SET = "set";
export const RESET = "reset";
export const INVALID_LINK = "invalid-link";
export const PASSWORD = "password";
export const REQUEST_RESET = "request-reset";
export const AUTH = "auth";
export const SET_PASSWORD = join_paths([PASSWORD, SET, TOKEN]);
export const RESET_PASSWORD = join_paths([PASSWORD, RESET, TOKEN]);
export const REQUEST_PASSWORD_RESET = join_paths([PASSWORD, REQUEST_RESET]);
export const INVALID_PASSWORD_LINK = join_paths([PASSWORD, INVALID_LINK]);
export const LOGIN = "login";
export const LOGIN_ABSOLUTE = "/" + LOGIN;
export const STRONG_IDENTIFICATION = "strong-identification";
export const IDENTIFICATION_CALLBACK = "identification-callback";
export const IDENTIFICATION_FAILED = "identification-failed";

// Corporate admin views
export const CORPORATE_ADMIN_HOME = join_paths([CORPORATE_ADMIN_PREFIX, HOME]);
export const CORPORATE_ADMIN_COMPANIES = "companies";
export const CORPORATE_ADMIN_OTHER_SETTINGS = "other_settings";
export const CORPORATE_ADMIN_SERVICE_SETTINGS = "service_settings";
export const CORPORATE_ADMIN_SALES_SETTINGS = "sales_settings";
export const CORPORATE_ADMIN_INTEGRATIONS = "integrations";

// Regional admin views
export const REGIONAL_ADMIN_HOME = join_paths([REGIONAL_ADMIN_PREFIX, HOME]);
export const REGIONAL_ADMIN_CUSTOMERS = "customer";
export const REGIONAL_ADMIN_SALES = "sales";
export const REGIONAL_ADMIN_MEDICAL_STAFF = "medicalstaff";
export const REGIONAL_ADMIN_MEDICAL_STAFF_EMPLOYER = "employer";
export const REGIONAL_ADMIN_MEDICAL_STAFF_EMPLOYEE = "employee";
export const REGIONAL_ADMIN_PURCHASE_INVOICES = "purchase_invoices";
export const REGIONAL_ADMIN_SALE_INVOICES = "sales_invoices";
export const REGIONAL_ADMIN_FILE_BANK = "file_bank";
export const REGIONAL_ADMIN_LABORATORY = "laboratory";
export const REGIONAL_ADMIN_USERS = "users";
export const REGIONAL_ADMIN_PERMISSION_SETTINGS = "permission_settings";
export const REGIONAL_ADMIN_HEALTHCARE_TEAMS = "healthcare_teams";

// Customer admin views
export const CUSTOMER_ADMIN_HOME = join_paths([CUSTOMER_ADMIN_PREFIX, HOME]);
export const CUSTOMER_ADMIN_EMPLOYEES = "employees";
export const CUSTOMER_ADMIN_HEALTH = "health";
export const CUSTOMER_ADMIN_COMPANY_INFORMATION = "company";
export const CUSTOMER_ADMIN_SICK_LEAVES = "sick_leaves";
export const CUSTOMER_ADMIN_FILES = "files";
// export const CUSTOMER_ADMIN_REMEMBERING = "remembering";
export const CUSTOMER_ADMIN_SETTINGS = "settings";

// Doctor views
export const DOCTOR_HOME = join_paths([DOCTOR_PREFIX, HOME]);
export const TEST_SCS = "test-scs";
export const TREATMENT_EVENT = "treatmentevent";
export const CALENDAR = "calendar";
export const APPOINTMENT = "appointment";
export const TREATMENT_EVENT_PAGE = join_paths([
    DOCTOR_PREFIX,
    TREATMENT_EVENT,
]);
export const APPOINTMENT_PAGE = join_paths([DOCTOR_PREFIX, APPOINTMENT]);
export const CREATE_SERVICE_EVENT_PAGE = join_paths([
    DOCTOR_PREFIX,
    APPOINTMENT,
    CREATE,
]);
export const SELECT_SERVICE_EVENT_PAGE = join_paths([
    DOCTOR_PREFIX,
    APPOINTMENT,
    SELECT,
]);
export const DOCTOR_SETTINGS = "settings";

// Supplier views
export const SUPPLIER_HOME = join_paths([SUPPLIER_PREFIX, HOME]);
export const PRODUCTS = "products";

// Surveys
export const SURVEYS = "surveys";
export const SELECTSURVEY = join_paths([SURVEYS, SELECT]);
export const NEW_SURVEY_ID = "new";
export const EDITSURVEYID = (id: string) =>
    join_paths([SURVEYS, EDIT, id.toString()]);
export const NEW_SURVEY = EDITSURVEYID(NEW_SURVEY_ID);

// Document templates
export const DOCUMENT_TEMPLATES = "documenttemplate";
export const SELECTDOCUMENTTEMPLATE = join_paths([DOCUMENT_TEMPLATES, SELECT]);
export const NEW_DOCUMENT_TEMPLATE_ID = "new";
export const EDITDOCUMENTTEMPLATEID = (id: string) =>
    join_paths([DOCUMENT_TEMPLATES, EDIT, id.toString()]);
export const NEW_DOCUMENT_TEMPLATE = EDITDOCUMENTTEMPLATEID(
    NEW_DOCUMENT_TEMPLATE_ID,
);

export const CAMPAIGNS = "campaigns";
export const CAMPAIGNS_PATH = join_paths([CAMPAIGNS]);

export const getUrlCampaignResults = (
    campaignId: string,
    userId: string = "",
) => `/campaigns/results/${campaignId}/${userId}`;

export const getUrlMedicalStaffEmployer = (
    customerId: string,
    absolute: boolean,
) => {
    if (absolute) {
        return join_paths(
            [
                REGIONAL_ADMIN_PREFIX,
                REGIONAL_ADMIN_MEDICAL_STAFF,
                REGIONAL_ADMIN_MEDICAL_STAFF_EMPLOYER,
                customerId,
            ],
            absolute,
        );
    } else {
        return join_paths(
            [REGIONAL_ADMIN_MEDICAL_STAFF_EMPLOYER, customerId],
            absolute,
        );
    }
};

export const getUrlMedicalStaffEmployee = (
    userId: string,
    absolute: boolean,
) => {
    if (absolute) {
        return join_paths(
            [
                REGIONAL_ADMIN_PREFIX,
                REGIONAL_ADMIN_MEDICAL_STAFF,
                REGIONAL_ADMIN_MEDICAL_STAFF_EMPLOYEE,
                userId,
            ],
            absolute,
        );
    } else {
        return join_paths(
            [REGIONAL_ADMIN_MEDICAL_STAFF_EMPLOYEE, userId],
            absolute,
        );
    }
};

export const getUrlMedicalStaff = (absolute: boolean) => {
    if (absolute) {
        return join_paths(
            [REGIONAL_ADMIN_PREFIX, REGIONAL_ADMIN_MEDICAL_STAFF],
            absolute,
        );
    } else {
        return join_paths([REGIONAL_ADMIN_MEDICAL_STAFF], absolute);
    }
};

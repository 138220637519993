import { Scalars } from "types/graphql";
import { parseISO } from "date-fns";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { AuthPageTabsType, AuthPermissions } from "Entity/Authentication/auth";

export type PageNameType =
    | "companies_and_users"
    | "service_settings"
    | "sales_settings"
    | "other_settings"
    | "settings"
    | "customers"
    | "sales"
    | "campaigns"
    | "medical_staff"
    | "invoices"
    | "employees"
    | "occupational_health"
    | "sick_leave_settings"
    | "files"
    | "service_event"
    | "treatment_event"
    | "calendar"
    | "purchase_invoices"
    | "sales_invoices"
    | "products"
    | "laboratory"
    | "file_bank"
    | "users"
    | "permission_settings"
    | "healthcare_teams"
    | "sports"
    | "company_information"
    | "api_and_integrations";

// use a translation key as a name
export type PageType =
    | {
          url?: never;
          name: PageNameType;
          icon: IconDefinition;
          children: PageType[];
          perms?: never;
          disabled?: boolean;
          infoText?: string;
      }
    | {
          url: string;
          name: PageNameType;
          icon: IconDefinition;
          children?: never;
          perms: AuthPageTabsType | AuthPermissions | undefined;
          disabled?: boolean;
          infoText?: string;
      };

export type Maybe<T> = T | null;

function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    if (value === null || value === undefined) return false;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const testDummy: TValue = value;
    return true;
}

/**
 * Select non-null values of array of nullables (e.g. graphql Array<Maybe<SomeType>> constructs)
 * @example
 *  arrayContainsMaybe: Array<Maybe<That>> = ...
 *  arrayActualItems = extractMaybeArray(arrayContainsMaybe) # -> That[]
 * @param value list of nullables or not anything
 * @returns list of non-null items (of type T) in array or empty array if input is falsy
 */
export function extractMaybeArray<T>(
    value: Maybe<T>[] | null | undefined,
): T[] {
    if (value === null || value === undefined) {
        return [];
    } else {
        return value.filter(notEmpty);
    }
}

export function extractMaybeValue<T>(
    value: T | null | undefined,
    defaultValue: T,
): T {
    if (value === null || value === undefined) {
        return defaultValue;
    } else {
        return value;
    }
}

export const parseDate = (value: Scalars["Date"]["input"]) => {
    return parseISO(value);
};

export const isValidDate = (value: Date): boolean => {
    // An invalid date object returns NaN for getTime() and NaN is the only
    // object not strictly equal to itself.
    // eslint-disable-next-line no-self-compare
    return value.getTime() === value.getTime();
};

export const dateToIsoString = (date: Date): Scalars["Date"]["input"] => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
};

export const getDateStrFromIsoStr = (value: string): string => {
    return value.substring(0, 10);
};

function omitTypename<T>(key: string, value: T): T | undefined {
    return key === "__typename" ? undefined : value;
}

export function removeTypenameFields<T>(object: T): T {
    return JSON.parse(JSON.stringify(object), omitTypename) as T;
}

interface Code {
    code: Scalars["String"]["input"];
}

/**
 * Some Kela provided boolean like codes (for example codesystem 1.2.246.537.6.112) can be represented by letters.
 * CodeToBoolean function is used to convert these values to boolean
 * @param value object containing code attribute as string.
 * @param trueCode letter leading to true value, default value is set to K according to codesystem 1.2.246.537.6.112
 * @param falseKode letter leading to false value, default value is set to E according to codesystem 1.2.246.537.6.112
 */
export const codeToBoolean = (
    value: Maybe<Code> | undefined,
    trueCode: string = "K",
    falseKode: string = "E",
): boolean | undefined => {
    if (value?.code === trueCode) {
        return true;
    } else if (value?.code === falseKode) {
        return false;
    } else {
        return undefined;
    }
};
